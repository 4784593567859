import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Divider, Drawer, withStyles } from '@material-ui/core'
import AttachMoneyTwoToneIcon from '@material-ui/icons/AttachMoneyTwoTone';
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone'
import CasinoTwoToneIcon from '@material-ui/icons/CasinoTwoTone';
import CategoryTwoToneIcon from '@material-ui/icons/CategoryTwoTone';
import CheckBoxTwoToneIcon from '@material-ui/icons/CheckBoxTwoTone';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone'
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import DirectionsTwoToneIcon from '@material-ui/icons/DirectionsTwoTone';
import EmojiEventsTwoToneIcon from '@material-ui/icons/EmojiEventsTwoTone'
import EventNoteTwoToneIcon from '@material-ui/icons/EventNoteTwoTone';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import FilterFramesTwoToneIcon from '@material-ui/icons/FilterFramesTwoTone';
import HistoryTwoToneIcon from '@material-ui/icons/HistoryTwoTone';
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone';
import ImageTwoToneIcon from '@material-ui/icons/ImageTwoTone';
import KitchenTwoToneIcon from '@material-ui/icons/KitchenTwoTone';
import LocalAtmTwoToneIcon from '@material-ui/icons/LocalAtmTwoTone';
import LocalConvenienceStoreTwoToneIcon from '@material-ui/icons/LocalConvenienceStoreTwoTone'
import LocalOfferTwoToneIcon from '@material-ui/icons/LocalOfferTwoTone'
import LocalMallTwoToneIcon from '@material-ui/icons/LocalMallTwoTone';
import MessageTwoToneIcon from '@material-ui/icons/MessageTwoTone';
import MonetizationOnTwoToneIcon from '@material-ui/icons/MonetizationOnTwoTone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import PersonIcon from '@material-ui/icons/Person';
import ReceiptTwoToneIcon from '@material-ui/icons/ReceiptTwoTone';
import StoreIcon from '@material-ui/icons/StoreTwoTone'
import SwapHorizTwoToneIcon from '@material-ui/icons/SwapHorizTwoTone';
import ShoppingBasketTwoToneIcon from '@material-ui/icons/ShoppingBasketTwoTone'
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import VpnKeyIcon from '@material-ui/icons/VpnKey'

import { authActions } from '../../../../_actions'
import { connect, useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Profile, SidebarNav } from './components'
import { Collapse, List, ListItem, Typography } from '@material-ui/core'

import packageInfo from '../../../../../package.json'

const styles = (theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  nav: {
    marginBottom: theme.spacing(1)
  }
})

const Sidebar = ({ staticContext, ...props }) => {

  const { open, variant, onClose, className, ...rest } = props
  const dispatch = useDispatch()
  const { user, type } = props
  const [openMenuPedidos, setOpenMenuPedidos] = useState(false);
  const [openMenuFidelidades, setOpenMenuFidelidades] = useState(false);
  const [openMenuCadastros, setOpenMenuCadastros] = useState(false);
  const [openMenuOperacao, setOpenMenuOperacao] = useState(false);
  const [openMenuCustos, setOpenMenuCustos] = useState(false);
  const [isClickedOptionSubmenu, setIsClickedOptionSubMenu] = useState(false);

  const logout = () => {
    dispatch(authActions.logout())
  }
  const { classes } = props

  const isDisabled = useSelector((state) => {
    return false
  })

  const handleClickPedidos = () => {
    setOpenMenuPedidos(!openMenuPedidos);
  };

  const handleClickOperacao = () => {
    setOpenMenuOperacao(!openMenuOperacao);
  };

  const handleClickCadastros = () => {
    setOpenMenuCadastros(!openMenuCadastros);
  };

  const pagesFidelidadesItens = [
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      divider: true,
      title: 'Home',
      href: '/fidelidade/home',
      rule: 'dashboard.view',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <HomeTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Banners',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      href: '/banners',
      rule: 'banners',
      icon: <ImageTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Campanhas',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      href: '/campanhas',
      rule: 'campanhas',
      icon: <EventNoteTwoToneIcon />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Clientes',
      href: '/clientes',
      rule: 'dashboard.view',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <PeopleOutlineTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Metas',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      href: '/metas',
      rule: 'metas',
      icon: <TrackChangesIcon />
    },
    {
      disabled: isDisabled,
      title: 'Notificações',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      href: '/notificacoes',
      rule: 'notificacoes',
      icon: <NotificationsIcon />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Ranking',
      href: '/ranking',
      rule: 'relatorios.list',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <EmojiEventsTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Relatório de Pontos',
      href: '/relatorioPontos',
      rule: 'relatorios.admin',
      icon: <DescriptionTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Relatório de Trocas',
      href: '/relatorioTrocas',
      rule: 'relatorios.admin',
      icon: <SwapHorizTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Sorteios',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      href: '/sorteios',
      rule: 'sorteios',
      icon: <CasinoTwoToneIcon />
    }
  ]

  const pagesPedidosItens = [
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Pedidos de Compra',
      href: '/pedidosCompras',
      divider: true,
      rule: 'pedidosCompras',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <ShoppingBasketTwoToneIcon />,
    },
    {
      disabled: isDisabled,
      title: 'Planilhas de Contagem',
      clickedSub: isClickedOptionSubmenu,
      href: '/checklists',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      rule: 'checklists',
      icon: <CheckBoxTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Fornecedores',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      href: '/fornecedores',
      rule: 'fornecedores',
      icon: <LocalMallTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Insumos',
      href: '/insumos',
      rule: 'totvsInsumos',
      icon: <KitchenTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Listas de Preços',
      clickedSub: isClickedOptionSubmenu,
      href: '/listasPrecos',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      rule: 'listasPrecos',
      icon: <LocalOfferTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Relatório de Fornecedores de Insumos',
      href: '/relatorioTotvsInsumosUnidadesFornecedores',
      rule: 'relatorios.list',
      icon: <FilterFramesTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Relatório de Nível de Serviço',
      href: '/relatorioNivelServico',
      rule: 'relatorios.admin',
      icon: <AttachMoneyTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Relatório de Divergências FT',
      href: '/relatorioDivergenciasFichaTecnica',
      rule: 'relatorios.admin',
      icon: <ReceiptTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Relatório de Venda de Insumos',
      href: '/relatorioVendasInsumos',
      rule: 'relatorios.list',
      icon: <AttachMoneyTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Unidades de Insumos',
      href: '/insumosUnidades',
      rule: 'totvsInsumosUnidades',
      icon: <KitchenTwoToneIcon />
    }
  ]

  const pagesOperacao = [
    {
      disabled: isDisabled,
      title: 'Checklists de Operação',
      clickedSub: isClickedOptionSubmenu,
      href: '/checklistsOperacoesCards/categorias',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      rule: 'checklistsOperacoes',
      icon: <CheckBoxTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Cadastro de Checklists de Operação',
      clickedSub: isClickedOptionSubmenu,
      href: '/checklistsOperacoes',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      rule: 'respostasChecklists.create',
      icon: <CheckBoxTwoToneIcon />
    }
  ]

  const pagesCustos = [
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      divider: true,
      title: 'Home',
      href: '/compras/home',
      rule: 'dashboard.view',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <HomeTwoToneIcon />
    },
    /*{
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      divider: true,
      title: 'Painel de dados',
      href: '/dashboardCompras',
      rule: 'dashboard.view',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <DashboardTwoToneIcon />
    },*/
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Compras',
      href: '/compras',
      divider: true,
      rule: 'compras',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <ShoppingBasketTwoToneIcon />,
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Relatório Comparativo de Preços',
      href: '/relatorioComprasComparativoPrecos',
      rule: 'relatorios.list',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <EmojiEventsTwoToneIcon />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Relatório Compras x Vendas',
      href: '/relatorioComprasVendas',
      rule: 'relatorios.list',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <EmojiEventsTwoToneIcon />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Relatório Compras',
      href: '/relatorioCompras',
      rule: 'relatorios.admin',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <LocalAtmTwoToneIcon />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Relatório Conversões',
      href: '/relatorioConversoes',
      rule: 'relatorios.admin',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <DirectionsTwoToneIcon />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Relatório Preços',
      href: '/relatorioPrecos',
      rule: 'relatorios.admin',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <MonetizationOnTwoToneIcon />
    }
  ]

  const pagesCadastrosItens = [
    {
      title: 'Categorias',
      href: `/categoriasCompras`,
      rule: 'categoriasCompras',
      clickedSub: isClickedOptionSubmenu,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <CategoryTwoToneIcon />
    },
    {
      title: 'Certificados',
      href: `/certificados`,
      rule: 'certificados',
      clickedSub: isClickedOptionSubmenu,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <CheckCircleTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Grupos',
      href: '/grupos',
      rule: 'grupos',
      clickedSub: isClickedOptionSubmenu,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <StoreIcon />
    },
    {
      title: 'Lojas',
      href: `/lojas`,
      clickedSub: isClickedOptionSubmenu,
      rule: 'lojas',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <LocalConvenienceStoreTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Mensagens/Alertas',
      href: '/mensagens',
      rule: 'mensagens',
      icon: <MessageTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Permissões',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      href: '/permissoes',
      rule: 'permissoes',
      icon: <VpnKeyIcon />
    },
    {
      disabled: isDisabled,
      title: 'Produtos',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      href: '/produtos',
      rule: 'produtos',
      icon: <ShoppingBasketTwoToneIcon />
    },
    {
      disabled: isDisabled,
      title: 'Unidades',
      href: '/unidades',
      rule: 'unidades',
      clickedSub: isClickedOptionSubmenu,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <KitchenTwoToneIcon />
    },
    {
      title: 'Usuários',
      href: '/usuarios',
      rule: 'usuarios',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      divider: true,
      icon: <PersonIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      clickedSub: isClickedOptionSubmenu,
      title: 'Relatório de Logs',
      href: '/relatorioLogs',
      rule: 'relatorios.admin',
      icon: <HistoryTwoToneIcon />
    },
    {
      disabled: isDisabled,
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      title: 'Parâmetros',
      clickedSub: isClickedOptionSubmenu,
      href: '/parametros',
      rule: 'parametros',
      icon: <BuildTwoToneIcon />
    }
  ]

  const pagesSair = [
    {
      title: 'Sair',
      href: '/login',
      icon: <ExitToAppTwoToneIcon />,
      onClick: () => {
        logout()
      }
    }
  ]

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <PerfectScrollbar>
        <div {...rest} className={clsx(classes.root, className)}>
          <Profile user={user} />
          <Divider className={classes.divider} />
          {type == 'G' ?
            <>
              <SidebarNav pages={pagesCadastrosItens} style={{ paddingTop: 0 }} />
              <div>
                <Collapse in={openMenuCadastros} timeout="auto">
                  <List component="div" disablePadding>
                    <ListItem style={{ paddingTop: 0 }}>
                      <SidebarNav pages={pagesCadastrosItens} />
                    </ListItem>
                  </List>
                </Collapse>
              </div>
              <SidebarNav pages={pagesSair} />
            </>
            :
            type == 'F' ?
              <>
                <SidebarNav pages={pagesFidelidadesItens} style={{ paddingTop: 0 }} />
                <div>
                  <Collapse in={openMenuFidelidades} timeout="auto">
                    <List component="div" disablePadding>
                      <ListItem style={{ paddingTop: 0 }}>
                        <SidebarNav pages={pagesFidelidadesItens} />
                      </ListItem>
                    </List>
                  </Collapse>
                </div>
                <SidebarNav pages={pagesSair} />
              </>
              :
              type == 'P' ?
                <>
                  <SidebarNav pages={pagesPedidosItens} style={{ paddingTop: 0 }} />
                  <div>
                    <Collapse in={openMenuPedidos} timeout="auto">
                      <List component="div" disablePadding>
                        <ListItem style={{ paddingTop: 0 }}>
                          <SidebarNav pages={pagesPedidosItens} />
                        </ListItem>
                      </List>
                    </Collapse>
                  </div>
                  <SidebarNav pages={pagesSair} />
                </>
                :
                type == 'O' ?
                  <>
                    <SidebarNav pages={pagesOperacao} style={{ paddingTop: 0 }} />
                    <div>
                      <Collapse in={openMenuOperacao} timeout="auto">
                        <List component="div" disablePadding>
                          <ListItem style={{ paddingTop: 0 }}>
                            <SidebarNav pages={pagesOperacao} />
                          </ListItem>
                        </List>
                      </Collapse>
                    </div>
                    <SidebarNav pages={pagesSair} />
                  </>
                  :
                  type == 'C' ?
                    <>
                      <SidebarNav pages={pagesCustos} style={{ paddingTop: 0 }} />
                      <div>
                        <Collapse in={openMenuCustos} timeout="auto">
                          <List component="div" disablePadding>
                            <ListItem style={{ paddingTop: 0 }}>
                              <SidebarNav pages={pagesCustos} />
                            </ListItem>
                          </List>
                        </Collapse>
                      </div>
                      <SidebarNav pages={pagesSair} />
                    </>
                    : null
          }
          <Typography variant="body2" align="center">
            Versão do sistema: {packageInfo.version}
          </Typography>
        </div>
      </PerfectScrollbar>
    </Drawer >
  )
}


Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  type: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user
  }
}
const connectedSidebarPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Sidebar))
)
export { connectedSidebarPage as Sidebar }
