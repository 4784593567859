const initialState = {
  nome: '',
  totalFidelizacoes: 0,
  totalLojas: 0,
  nomeLojaFavorita: '',
  pontuacao: 0,
  totalItens: 0,
  top: '',
  nomeLojaTop: '',
  isLoading: false
}

export function retrospectiva(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_RETROSPECTIVA_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'FETCHED_RETROSPECTIVA_SUCCESS':
      return {
        ...state,
        isLoading: false,
        nome: action.nome,
        totalFidelizacoes: action.totalFidelizacoes,
        totalLojas: action.totalLojas,
        nomeLojaFavorita: action.nomeLojaFavorita,
        pontuacao: action.pontuacao,
        totalItens: action.totalItens,
        top: action.top,
        nomeLojaTop: action.nomeLojaTop
      }
    case 'FETCHED_RETROSPECTIVA_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'RETROSPECTIVA_CLEAR':
      return initialState
    case 'RETROSPECTIVA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'RETROSPECTIVA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
